<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card>
        <validation-observer #default="{ invalid }" ref="refFormObserver">
          <b-form class="p-1">
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Formulario de Registro de Lecciones Aprendidas</h5>
              </b-col>
            </b-row>          
            <b-row class="">
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  class="h-100 d-inline-block w-100"
                  name="ambit"
                  rules="requeridoE"
                >
                  <b-form-group label="Ambito" label-for="ambit" class="h-100">
                    <div class="d-flex w-100 justify-content-center align-items-center mt-2">
                      <b-form-radio v-model="dataItems.ambit" name="ambit" value="oficina">
                        Oficina Principal y Sedes
                      </b-form-radio>
                      <b-form-radio
                        v-model="dataItems.ambit"
                        name="ambit"
                        value="proyecto"
                        class="ml-2"
                      >
                        Proyecto
                      </b-form-radio>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" v-if="dataItems.ambit == 'proyecto'" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="project" rules="">
                  <b-form-group label="Proyecto" label-for="project" class="">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="proyectos"
                      label="code"
                      input-id="project"
                      :reduce="(proyectos) => proyectos.id"
                      placeholder="Proyecto"
                      v-model="dataItems.project_id"                      
                      ><template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" v-else class="">
                <validation-provider #default="{ errors }" name="area" rules="">
                  <b-form-group label="Area" label-for="area" class="">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="areas"
                      label="text"
                      input-id="area"
                      :reduce="(areas) => areas.value"
                      placeholder="Area"
                      v-model="dataItems.area_name"
                      >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>          
              <b-col md="6">
                <validation-provider #default="{ errors }" name="register_by" rules="">
                  <b-form-group label="Registrado por" label-for="register_by">
                    <b-form-input
                      v-model="dataItems.register_by"
                      id="register_by"
                      placeholder="Resgistrado por"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider #default="{ errors }" name="email" rules="correo">
                  <b-form-group label="Email" label-for="email">
                    <b-form-input
                      v-model="dataItems.email"
                      id="email"
                      type="email"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>             
              <b-col md="6">
                <validation-provider #default="{ errors }" name="date_specification" rules="">
                  <b-form-group label="Fecha de Registro" label-for="date_specification">
                    <flat-pickr
                      id="date_specification"
                      v-model="dataItems.date_specification"
                      class="form-control"
                      :config="config"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="division" rules="">
                  <b-form-group label="Division" label-for="division">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="divisiones"
                      label="text"
                      input-id="division"
                      :reduce="(records) => records.value"
                      v-model="dataItems.division"
                      placeholder="Division"
                      @input="changeDiv(dataItems.division)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">             
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="category" rules="">
                  <b-form-group label="Título" label-for="category">
                    <v-select
                      v-if="dataItems.division != 'otros'"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typesWorks"
                      label="text"
                      input-id="category"
                      v-model="dataItems.type_work"
                      :reduce="(typesWorks) => typesWorks.value"
                      placeholder="Título"
                      class="select-obra"
                    >
                    </v-select>
                    <b-form-input
                      v-else
                      v-model="dataItems.type_work"
                      id="category"
                      placeholder="Título"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="speciality" rules="">
                  <b-form-group label="Especialidad" label-for="speciality">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="specialitys"
                      label="text"
                      input-id="speciality"
                      :reduce="(specialitys) => specialitys.value"
                      v-model="dataItems.speciality"
                      placeholder="Especialidad"
                      class="select-obra"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="typification" rules="">
                  <b-form-group label="Tipificación" label-for="typification">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="typifications"
                      label="text"
                      input-id="typification"
                      :reduce="(typifications) => typifications.value"
                      v-model="dataItems.typification"
                      placeholder="Tipificación"
                      class="select-obra"
                    >
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>             
              <b-col md="6" v-if="dataItems.typification == 'otros'">
                <validation-provider #default="{ errors }" name="typification_others" rules="">
                  <b-form-group label="Otros (Especificar)" label-for="typification_others">
                    <b-form-input
                      v-model="dataItems.typification_others"
                      id="typification_others"
                      placeholder="Especificar"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  class="h-100 d-inline-block w-100"
                  name="is_security"
                  rules="requeridoE"
                >
                  <b-form-group label="Es de SSOMA?" label-for="security" class="h-100" style="font-weight: bolder; color: black; padding: 0.5rem; border-style: dashed;">
                    <div class="d-flex w-50 justify-content-center align-items-center mt-1">
                      <span class="mr-2" style="font-weight: bolder; color: black">No</span>
                      <b-form-checkbox v-model="dataItems.is_security" name="is_security" switch>                       
                      </b-form-checkbox>                       
                       <span class="ml-2" style="font-weight: bolder; color: black">Si</span>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col sm="12">
                <h5>Lección Aprendida</h5>
              </b-col>
              <b-col sm="12">
                <validation-provider #default="{ errors }" name="title_lesson" rules="">
                  <b-form-group label="" label-for="title_lesson" class="mb-0">
                    <b-form-textarea
                      id="title_lesson"
                      :placeholder="tl_placeholder"
                      rows="4"
                      max-rows="4"
                      v-model="dataItems.title_lesson"
                      no-resize
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>             
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col sm="12">
                <h5>Descripción de la Ocurrencia e Impacto</h5>
              </b-col>
              <b-col sm="12">
                <validation-provider #default="{ errors }" name="description_impact" rules="">
                  <b-form-group label="" label-for="description_impact" class="mb-0">
                    <b-form-textarea
                      id="description_impact"
                      :placeholder="descr_placeholder"
                      rows="4"
                      max-rows="4"
                      v-model="dataItems.description_impact"
                      no-resize
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Evaluación de las Causas</h5>
              </b-col>
              <b-col md="12">
                <validation-provider #default="{ errors }" name="assessment_causes" rules="">
                  <b-form-textarea
                    id="assessment_causes"
                    p :placeholder="eval_placeholder"
                    rows="6"
                    max-rows="6"
                    v-model="dataItems.assessment_causes"
                    no-resize
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Mitigación del Impacto</h5>
              </b-col>
              <b-col md="12">
                <validation-provider #default="{ errors }" name="impact_mitigation" rules="">
                  <b-form-textarea
                    id="impact_mitigation"
                    :placeholder="mit_placeholder"
                    rows="6"
                    max-rows="6"
                    no-resize
                    v-model="dataItems.impact_mitigation"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Análisis y Conclusiones</h5>
              </b-col>
              <b-col md="12">
                <validation-provider #default="{ errors }" name="conclusions" rules="">
                  <b-form-textarea
                    id="conclusions"
                    :placeholder="analisis_placeholder"
                    rows="6"
                    max-rows="6"
                    no-resize
                    v-model="dataItems.conclusions"
                    disabled
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>        
        <validation-observer #default="{ invalid }" ref="refFormObserverFiles">
          <b-form class="p-1">
            <b-row class="mb-1">
              <b-col md="6">
                <h5>Archivos Adjuntos</h5>
              </b-col>
              <b-col md="6" class="d-flex justify-content-md-end justify-content-center">
                <b-button @click="saveTemp" :variant="'success'" class=""> Guardar </b-button>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="6">
                <validation-provider #default="{ errors }" name="description" rules="requeridoE">
                  <b-form-group label="Descripción" label-for="description">
                    <b-form-input
                      v-model="items.description"
                      id="description"
                      placeholder="Descripción"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="">
                <validation-provider #default="{ errors }" name="file" rules="requeridoE">
                  <b-form-group label="Archivo" label-for="file">
                    <b-form-file
                      v-model="items.file"
                      placeholder="Seleccione un archivo o arrastre aqui..."
                      drop-placeholder="Arrastre aqui..."
                      id="file"
                    />
                    <small class="text-warning">Maximo tamaño de archivo 20mb</small>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <b-row>
          <b-col md="12">
            <b-table
              class="position-relative"
              :hover="true"
              :items="files"
              responsive
              :fields="fields"
              show-empty
              empty-text="No existen"
              @sort-changed="sortChanged"
              no-border-collapse
            >
              <!-- Column: Actions -->
              <!-- <template v-slot:cell(nro)="{ item, field: { key, index } }">
                <span>{{index}}</span>
              </template> -->
              <template #cell(created_at)="data">
                <span>{{ moment(data.item.created_at).format('yyyy-MM-DD') }}</span>
              </template>
              <template #cell(actions)="data">
                <div class="d-flex align-items-center">
                  <b-button
                    size="sm"
                    class=""
                    v-b-tooltip.noninteractive.hover.top="'Eliminar'"
                    variant="flat-danger"
                    @click.prevent="deleteTemp(data.item)"
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </div>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col sm="3">
                  <b-form-group
                    label-cols="4"
                    label-cols-md="4"
                    label-size="md"
                    label="Entradas"
                    label-for="input-md"
                  >
                    <b-form-select
                      v-model="showEntrie"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="entries"
                      @change="changeSizePage"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="3" class="mt-75" style="font-size: 12px">
                  <span> {{ totalElements }} Registros en total</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalElements"
                    :per-page="showEntrie"
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                    @change="cambioPagina"
                    pills
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="15" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="15" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="p-1 d-flex justify-content-center">
            <b-button class="mr-2 mb-1 mb-md-0" variant="success" @click="saveData(dataItems)">
              <span class="text-nowrap"> Registrar </span>
            </b-button>
            <b-button class="mr-2 mb-1 mb-md-0" variant="danger" @click="cancel">
              <span class="text-nowrap"> Volver </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import LessonService from '@/services/LessonService'
import ShortcutButtonsPlugin from 'shortcut-buttons-flatpickr'
import ProjectsService from '@/services/ProjectsService'
import AreasService from '@/services/AreasService'
import flatPickr from 'vue-flatpickr-component'
const APIURL = process.env.APIURLFILE

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      required,
      api_url: APIURL,
      showLoading: false,
      tabIndex: 0,
      records: [],
      files: [],
      filesTemp: [],
      fields: [
        { key: 'id', label: 'Nro', sortable: false },
        { key: 'description', label: 'Descripción', sortable: false },
        { key: 'created_at', label: 'Fecha Actualización', sortable: false },
        { key: 'file_name', label: 'Nombre Archivo', sortable: false },
        { key: 'actions', label: 'Acciones' }
      ],
      prioridades: [
        {
          id: 1,
          name: 'Alta'
        },
        {
          id: 2,
          name: 'Media'
        },
        {
          id: 3,
          name: 'Baja'
        }
      ],
      config: {
        plugins: [
          ShortcutButtonsPlugin({
            theme: 'dark',
            button: [{ label: 'Hoy' }],
            onClick(index, fp) {
              let date = index ? new Date(Date.now() + 24 * index * 60 * 60 * 1000) : new Date()
              fp.setDate(date)
              fp.close()
            }
          })
        ],
        locale: {
          firstDayOfWeek: 1,
          weekdays: {
            shorthand: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
            longhand: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado']
          },
          months: {
            shorthand: [
              'Ene',
              'Feb',
              'Mar',
              'Abr',
              'May',
              'Jun',
              'Jul',
              'Ago',
              'Sep',
              'Оct',
              'Nov',
              'Dic'
            ],
            longhand: [
              'Enero',
              'Febrero',
              'Мarzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre'
            ]
          }
        }
      },   
      areas: [
        {
          text: 'Comunicaciones',
          value: 'COM'
        },
        {
          text: 'Tecnologías de las Información',
          value: 'TI'
        },
        {
          text: 'Selección y Desarrollo',
          value: 'SELDESA'
        },
        {
          text: 'Nómina y Relaciones Laborales',
          value: 'NOMRL'
        },
        {
          text: 'Responsabilidad Social y Bienestar',
          value: 'RRSS'
        },
        {
          text: 'Archivo',
          value: 'ARCHIV'
        },
        {
          text: 'Impuestos y Cuentas por Pagar',
          value: 'CXP'
        },
        {
          text: 'Finanzas y Seguros',
          value: 'FINANZAS'
        },
        {
          text: 'Costos y Control de Gestión',
          value: 'COCG'
        },
        {
          text: 'Contabilidad',
          value: 'CONTA'
        },
        {
          text: 'Logística',
          value: 'LOG'
        },
        {
          text: 'Almacén Central y Transporte',
          value: 'ALM'
        },
        {
          text: 'Negocios',
          value: 'NEG'
        },
        {
          text: 'Propuestas',
          value: 'PROP'
        },
        {
          text: 'SSOMA',
          value: 'SSOMAC'
        },
        {
          text: "Unidad de Equipos",
          value: "EQUIP",
        },
        {
          text: "Almacén Chilca",
          value: "ALMCHILCA",
        },
        {
          text: 'Contratos',
          value: 'CONTRATOS'
        },
        {
          text: 'Legal',
          value: 'LEGAL'
        },
        {
          text: 'Planeamiento',
          value: 'PLANEAM'
        }
      ],
      typesWorks: [],
      divisiones: [
      {
          text: 'Infraestructura',
          value: 'infraestructura',
          categorys: [
            {
              text: 'Obras de Saneamiento',
              value: 'saneamiento'
            },
            {
              text: 'Puentes',
              value: 'puentes'
            },
            {
              text: 'Intercambios viales y pistas',
              value: 'viales-pistas'
            },
            {
              text: 'Aeropuertos',
              value: 'aeropuertos'
            },
            {
              text: 'Obras subterráneas',
              value: 'obras-subterraneas'
            },
            {
              text: 'Obras hidráulicas',
              value: 'obras-hidraulicas'
            },
            {
              text: 'Obras Portuarias',
              value: 'obras-portuarias'
            },
            {
              text: 'Edificaciones Industriales',
              value: 'edificaciones-industriales'
            },
            {
              text: 'Carreteras',
              value: 'carreteras'
            }
          ]
        },
        {
          text: 'Edificaciones',
          value: 'edificaciones',
          categorys: [
            {
              text: 'Vivienda',
              value: 'vivienda'
            },
            {
              text: 'Centros Comerciales',
              value: 'centros-comerciales'
            },
            {
              text: 'Oficinas',
              value: 'oficinas'
            },
            {
              text: 'Hoteles',
              value: 'hoteles'
            },
            {
              text: 'Talleres y almacenes',
              value: 'talleres-almacenes'
            }
          ]
        },
        {
          text: 'Mineria',
          value: 'gas/mineria',
          categorys: [
            {
              text: 'Lineas de Conducción',
              value: 'lineas-conduccion'
            },
            {
              text: 'Obras Civiles',
              value: 'obras-civiles'
            },
            {
              text: 'PADs',
              value: 'pads'
            },
            {
              text: 'Movimiento de Tierras',
              value: 'mov-tierras'
            },
            {
              text: 'Desarrollo y explotación subterráneo',
              value: 'de-subterraneo'
            },
            {
              text: 'Desarrollo y explotación T. abierto',
              value: 'de-abierto'
            }
          ]
        },
        {
          text: 'Petroleo',
          value: 'petroleo',
          categorys: [
            
          ]
        },
        {
          text: 'Otros',
          value: 'otros',
          categorys: [
            
          ]
        }
      ],
      specialitys: [
        {
          text: 'Movimiento de Tierras',
          value: 'mov-tierra'
        },
        {
          text: 'Civil',
          value: 'civil'
        },
        {
          text: 'Estructuras',
          value: 'estructuras'
        },
        {
          text: 'Mecánica',
          value: 'mecanica'
        },
        {
          text: 'Electrica e Instrumentacion',
          value: 'electrica'
        },
        {
          text: 'Sanitaria',
          value: 'sanitaria'
        },
        {
          text: 'Otras',
          value: 'otras'
        }
      ],
      typifications: [
        {
          text: 'Logros Ejecución Proyecto',
          value: 'logros-ejecucion-proyecto'
        },
        {
          text: 'Problemas de Calidad',
          value: 'problemas-de-calidad'
        },
        {
          text: 'Problemas monto de propuesta',
          value: 'problemas-monto-de-propuesta'
        },
        {
          text: 'Fallas Ejecución',
          value: 'fallas-ejecucion'
        },
        {
          text: 'Problemas Plazo',
          value: 'problemas-plazo'
        },
        {
          text: 'Conflictos Adm. Contrato',
          value: 'conflictos-adm-contrato'
        },
        {
          text: 'Otros',
          value: 'otros'
        }
      ],
      proyectos: [],
      info_cost: '',
      info_term: '',
      estados: [
        {
          text: 'Alto',
          value: 'alto'
        },
        {
          text: 'Medio',
          value: 'medio'
        },
        {
          text: 'Bajo',
          value: 'bajo'
        }
      ],
      estadosCost: [
        {
          text: 'Alto',
          value: 'alto',
          description: 'CLA >=0.5% Monto del proyecto'
        },
        {
          text: 'Medio',
          value: 'medio',
          description: '0.2% Monto del proyecto < CLA < 0.5% Monto del proyecto'
        },
        {
          text: 'Bajo',
          value: 'bajo',
          description: 'CLA <=0.2% Monto del proyecto'
        }
      ],
      estadosTerm: [
        {
          text: 'Alto',
          value: 'alto',
          description: 'Si la actividad se retrasa más de 15 días de lo programado'
        },
        {
          text: 'Medio',
          value: 'medio',
          description: 'Si la actividad se retrasa entre 7 y 15 días de lo programado'
        },
        {
          text: 'Bajo',
          value: 'bajo',
          description: 'Si la actividad se retrasa 7 o menos días de lo programado'
        }
      ],
      showLoading: false,
      arrayFilterProyect: [],
      dataItems: {
        project_id: null,
        area_name: null,
        is_security:false,
        code: '',
        ambit: 'proyecto',
        register_by: '',
        email: '',
        date_specification: new Date(),
        date_last_update: null,
        speciality: '',
        division: '',
        type_work: '',
        typification: '',
        typification_others: '',
        title_lesson: '',
        description_impact: '',
        assessment_causes: '',
        impact_mitigation: '',
        conclusions: '',
        status:'PendientePorOficializar'
      },
      items: {
        description: '',
        file: null,
        lesson_id: null
      },
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      id: 0,
      sort: 'id',
      order: 'desc',
      tl_placeholder:'',
      descr_placeholder:'',
      eval_placeholder:'',
      mit_placeholder:'',
      analisis_placeholder:''
    }
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr
  },
  mounted() {
    this.dataItems.project_id = JSON.parse(localStorage.getItem('project_id'))
    this.getData()
    this.tl_placeholder = `El enunciado debe expresar la relación entre el resultado de un proceso o proyecto y los factores críticos, condiciones o causas que facilitaron u obstaculizaron el mismo.
    • Descripción de una situación final, resultado o consecuencia + verbo pasado introduciendo una relación (provenir de/depender de/a consecuencia de/etc.) + descripción de las condiciones o causas.`
    this.descr_placeholder = `Descripción del impacto sobre las actividades de construcción o las actividades del área de soporte.`
    this.eval_placeholder = `Para identificar las causas pueden realizar las siguientes preguntas:
    • ¿Qué/Cómo/Cuando/Donde + condujo a/generó/provocó/permitió/dificultó/etc. + dicho efecto?.`
    this.mit_placeholder = `Debemos identificar las acciones que se deben realizar para mitigar el impacto.
• ¿Qué acciones se realizaron para afrontar el evento?
• ¿Qué acciones podemos realizar en futuro para estar listos ante un evento?`
    this.analisis_placeholder = `• Análisis: interpretación de datos, tendencias, patrones o relaciones.
• Conclusiones: recomendaciones basadas en los hallazgos, acciones que se puedan tomar en futuro a partir de los resultados.`
  },
  methods: {
    cambioPagina(e) {
      this.currentPage = e
      this.totalElements = this.files.length
    },
    changeSizePage() {
      this.totalElements = this.files.length
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },
    cancel() {
      this.$router.go(-1);
    },
    async getData() {
      this.arrayFilterProyect.push({
			keyContains: 'show_lesson',
			key: 'equals',
			value: true
		})
      const url = `?limit=10000&`
      const url1 =
        `?limit=10000&filter=`+JSON.stringify(this.arrayFilterProyect)
      this.showLoading = true
      const respProyectos = await ProjectsService.getProyectos(url1, this.$store)
      console.log('respProyectos', respProyectos)
      if (respProyectos) {
        this.proyectos = respProyectos.data.rows
      }

      this.showLoading = false
    },
    // async selectProyect(id) {
    //   this.showLoading = true
    //   let url =
    //     `?limit=100&filter=` +
    //     JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id }])
    //   const respAreas = await AreasService.getAreas(url, this.$store)
    //   console.log('respAreas', respAreas)
    //   if (respAreas) {
    //     this.areas = respAreas.data.rows
    //   }
    //   this.showLoading = false
    // },
    changeDiv(name) {
      this.typesWorks = []
      this.dataItems.type_work = ''
      const typeWork = this.divisiones.filter((e) => e.value == name)
      console.log('typeWork', typeWork)
      if (typeWork.length > 0) {
        console.log('typeWork[0].categorys', typeWork[0].categorys)
        this.typesWorks = typeWork[0].categorys
      }
    },
    async saveData() {
      this.showLoading = true
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          console.log('this.dataItems', JSON.stringify(this.dataItems))
          this.dataItems.date_specification = new Date(this.dataItems.date_specification)
          const save = await LessonService.saveLesson(this.dataItems, this.$store)
          if (save.status) {
            if (this.files.length > 0) {
              let resp = null
              let datos = new FormData()
              for (let index = 0; index < this.files.length; index++) {
                const element = this.files[index]
                datos.append('description', element.description)
                datos.append('file', element.file)
                datos.append('lesson_id', save.data.id)
                resp = await LessonService.saveFile(datos, this.$store)
                if (resp.status) {
                  datos.delete('description')
                  datos.delete('file')
                  datos.delete('lesson_id')
                }
              }
              if (resp != null) {
                if (resp.status) {
                  this.$swal({
                    title: 'Registrado',
                    text: 'Los datos han sido registrados con exito.',
                    icon: 'success',
                    customClass: {
                      confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                  })
                  this.$router.push('/lecciones')
                } else {
                  this.$swal({
                    title: 'Error!',
                    text: 'Ocurrió un error al registrar los archivos.',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary'
                    },
                    buttonsStyling: false
                  })
                }
              } else {
                this.$swal({
                  title: 'Error!',
                  text: 'Ocurrió un error al guardar los archivos.',
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
                })
              }
            } else {
              this.$swal({
                title: 'Registrado',
                text: 'Los datos han sido registrados con exito.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
              this.$router.push('/lecciones')
            }
          } else {
            this.$swal({
              title: 'Error!',
              text: 'Ocurrió un error al registrar los datos de los formularios.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })            
          }
        }
      })
      this.showLoading = false
    },
    resetForm() {
      this.$refs.refFormObserverFiles.reset()
      this.items.description = ''
      this.items.file = null
    },
    saveTemp() {
      this.$refs.refFormObserverFiles.validate().then(async (success) => {
        if (success) {
          console.log('this.items', this.items)
          this.files.push({
            id: this.files.length + 1,
            description: this.items.description,
            file: this.items.file,
            created_at: new Date(),
            file_name: this.items.file.name
          })
          this.resetForm()
          this.totalElements = this.files.length
        }
      })
    },
    deleteTemp(data) {
      console.log('data', data)
      this.$swal({
        title: '¿Desea eliminar este archivo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.deleteDataTemp(data.id)
        }
      })
    },
    deleteDataTemp(id) {
      console.log('id', id)
      this.records = this.records.filter((e) => e.id != id)
      this.currentPage = 1
      this.totalElements = this.records.length
      this.$swal({
        icon: 'success',
        title: 'Eliminado!',
        text: 'El archivo ha sido eliminado.',
        customClass: {
          confirmButton: 'btn btn-success'
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.select-obra .vs__dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}
.shortcut-buttons-flatpickr-buttons {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.shortcut-buttons-flatpickr-button {
  background-color: rgb(115, 103, 240);
  border: 0px;
  padding: 5px 15px;
  border-radius: 5px;
  color: #fff;
}
.shortcut-buttons-flatpickr-button:hover {
  background-color: rgb(129, 119, 230);
}
</style>
